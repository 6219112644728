.title {
    color: #487c84
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
    margin-left: 20px;
}

.textArea {
    width: 100%;
}

.selectStyle {
    width: 49%;
}

.checkboxLabel {
    font-weight: 400;
    font-size: 16px;
    padding-left: 5px;
}

.dcPermission {
    margin-top: 7px;
}

.btnStyle {
    color: white !important;
    background-color: #487c84 !important;
    border-radius: 7px;
    height: 53px;
    border: 1px solid #487c84;
    margin: 10px;
    float: right;
}

.btnStyle:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-radius: 7px;
    border: 1px solid #487c84 !important;
    margin: 10px;

}
