.textAreaStyle {
    width: 100%;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;

}

.saveButton {
    width: 20%;
    margin-left: 30%;
}

.closeButton {
    width: 20%;
}

.buttonSendMC {
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    height: 80%;
    width: auto;
    margin-top: 5px;
    margin-bottom: -3px;
}

.buttons {
    text-align: right;
    margin-top: 5px;
}
