.linkBtn {
    color: #487c84 !important;
}

.saveBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;
}

.saveBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
}

.uploadBtn > span {
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.uploadSpinnerBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;

}

.uploadSpinnerBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;

}

.sheetTitle {
    margin: auto;
    display: inline-block;
}

.groupSheets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.acceptDelete {
    background-color: #487c84;
    border-color: transparent !important;

}

.acceptDelete:hover {
    background-color: #487c84 !important;
    border-color: transparent !important;

}

.rejectDelete {
    color: #487c84;
    background-color: transparent;
    border-color: transparent;
}

.rejectDelete:hover {
    color: #487c84 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.setupQueries {
    margin-right: 10px;
}

.divSaveBtn {
    margin-right: 10px;
}

.dialogStyle {
    width: 55%;
    height: 55%;
}


@media (min-height: 722px) {
    .dialogStyle {
        width: 55%;
        height: 35%;
    }
}

@media (min-height: 900px) {
    .dialogStyle {
        width: 50%;
        height: 26%;
    }
}


