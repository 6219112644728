.title {
    color: #487c84
}

.inputStyle {
    width: 100%
}

.dropDown {
    width: 100%;
}

.mainDiv {
    text-align: center;
    height: 150px;
    overflow-y: auto;
    padding-top: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 10px;
}

.toolbarStyle {
    height: 150px;
}

.subDiv {
    position: absolute;
    bottom: 0;
}

.customButton {

    float: right;
    background-color: #487c84 !important;
    border-color: transparent !important;
}

.iconInfo {
    padding-left: 7px;
}
