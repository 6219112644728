.title {
    color: #487c84
}

.inputStyle {
    width: 100%
}

.dropDown {
    width: 100%;
}

.mainDiv {
    text-align: center;
    position: relative;


}

.subDiv {
    position: absolute;
    bottom: 0;

}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.actionBtn:hover {
    background-color: #487c84 !important;
}

.p-button-label {
    font-weight: 700 !important;
}

.rightButton {
    float: right;
}
