
.iconCalendar > button {
    background-color: #487c84
}

.iconCalendar:hover > button:hover {
    background-color: #487c84
}


.title {
    color: #487c84
}


.inputStyle {
    width: 100%
}

.dropDown {
    width: 100%;
}

.calendar {
    width: 100%;
}

.customButton .p-button {


    background-color: #487c84 !important;
    border: 1px solid #487c84 !important;
}

.btnStyle {
    color: white !important;
    background-color: #487c84;
    border-radius: 7px;
    flex: 0 1 auto;
    float: right;
    height: 53px;
    border: 1px solid #487c84;
    margin: 10px;
}

.btnStyle:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-radius: 7px;
    flex: 0 1 auto;
    float: right;
    border: 1px solid #487c84 !important;
    margin: 10px;

}

.container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


.container div {
    padding: 20px;
    border: 1px solid black;
    margin: 10px;
}


