.DC {
    float: left;
    color: #487c84
}

.MN {
    float: right;
    color: #487c84
}

.C487c84 {
    color: #487c84;
}

.btnStyle {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;
}

.btnStyle:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
}

.buttonDownload {
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    height: 50%;
    width: auto;
    display: block;
    margin: auto;
}
