.btnStyle {
    background-color: #487c84;
    border-color: transparent !important;
    margin: 10px;

}

.btnStyle:hover {
    background-color: #487c84 !important;
    border-color: transparent !important;
    margin: 10px;
}

.actionCell {
    width: 2em;
    min-width: 2em;
    max-width: 2em;
}

.bigCell {
    width: 8em;
    min-width: 8em;
    max-width: 10em;
}
.cellWidth {
    width: 4em;
    min-width: 4em;
    max-width: 4em;
}
.dateCellWidth {
    width: 3em;
    min-width: 3em;
    max-width: 3em;
}
.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
    margin-left: 20px;
}
.buttons {
    text-align: right;
    margin-top: 5px;
}
