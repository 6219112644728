.dialog-header-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.text-center {
    text-align: center;
}
.data-table-container.p-datatable {
    min-height: 150px!important;
}
.datatable-centered.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    justify-content: center;

}
.datatable-loading-style .p-component-overlay {
    background-color: transparent!important;

}
.notifyBtnStyle {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;
    font-weight:600;
    margin-right:5px;
}

.notifyBtnStyle:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
    font-weight:600;
}
