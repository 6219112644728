.title {
    color: #487c84;
    font-weight: 700;
}

.spinner > svg > circle {
    stroke: #487c84 !important;
}


.downloadBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.downloadBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.downloadBtnSuccess {
    color: white !important;
    background-color: #3f9c35;
    border-color: #3f9c35;
}

.downloadBtnSuccess:hover {
    color: white !important;
    background-color: #3f9c35 !important;
    border-color: #3f9c35 !important;
}

.downloadBtnFailed {
    color: white !important;
    background-color: red;
    border-color: red;
}

.downloadBtnFailed:hover {
    color: white !important;
    background-color: red !important;
    border-color: red !important;
}
.center {
    align-content: center;
    justify-content: center;
    display: flex;
}


.header {
    background-color: #D8E2E5;
    margin-right: 1%;
    margin-left: 1%;
    display: flex;
    justify-content: space-between;
    padding-right: 10%;
    padding-left: 10%;


}
.flexDisplay
{
    display: flex;
}
.sheetStyle
{
    margin-left:20px;
}
.firstCol
{
    padding-left:10px
}

.iconButton
{
    height:0.5rem;
    width:0.5rem;
    margin:0 0 0 0;
}
.blackText
{
   color:#495057;
}
.greenText
{
        color:#3f9c35;
}