.btn {
    background-color: #487c84;
    border-color: transparent !important;
    margin-right: 0.5%;

}

.btn:hover {
    background-color: #487c84 !important;
    border-color: transparent !important;
    margin-right: 0.5%;

}

.tool {
    display: flex;
    flex-direction: row-reverse;
}