.description {
    text-overflow: ellipsis;
    width: 500px;
    overflow: hidden;
    margin: 0;
    z-index: 1;
}

.nowrap {
    white-space: nowrap;
}

.normal {
    white-space: pre-line;
}
