.titleQuery h4 {
    color: #487c84;
}

.titleH4Query {
    color: #487c84;
}

.titleQuery .p-card-title {
    color: #487c84;
}

.btn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.btn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.customButton {
    height: 45px;
    /*float: right;*/
    margin-left: 5px;
    margin-bottom: 2px;
    background-color: #487c84 !important;
    border-color: transparent !important;
}

