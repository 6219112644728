
.panel > div > span {
    color: #487c84;
}

.panel {
    padding: 0px;
}

.content {
    color: black;
}

.section {
    /*width:auto;*/
    width: 100%;
    margin-bottom: 1%;
}

.actionContent {
    display: flex;
    justify-content: center;
    align-items: center;
}
