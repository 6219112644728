.button {
    margin-left: 5px;
    margin-right: 5px;
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
    margin-left: 20px;
}
