.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;

}

.small {
    font-size: 14px;
    margin-left: 3px;
}

.warningFlag{
    color: darkorange;
}

