.spinner > svg > circle {
    stroke: #487c84 !important;
}

.progressBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.progressBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.acceptDelete {
    background-color: #487c84;
    border-color: transparent !important;

}

.acceptDelete:hover {
    background-color: #487c84 !important;
    border-color: transparent !important;

}

.rejectDelete {
    color: #487c84;
    background-color: transparent;
    border-color: transparent;
}

.rejectDelete:hover {
    color: #487c84 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}
