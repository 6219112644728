.noDataBtn {
    min-width: 160px;
}

.uploadBtn {
    color: white !important;
    background-color: transparent !important;
    border-color: #487c84;
    border-radius: 7px;
    margin-right: 5px;
    min-width: 160px;
}

.uploadBtn > span > span {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;
}

.uploadBtn > span {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;
}


.uploadBtn :hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
}

.uploadBtn > span :hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
}

.uploadBtn > span > span :hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
}

.title {
    color: #487c84;
    font-weight: 700;
}

.uploadSpinnerBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.uploadSpinnerBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.small {
    font-size: 11px;
    margin-left: 3px;
}

.downloadBtnFailed {
    color: white !important;
    background-color: red;
    border-color: red;
    margin-left: 2px;
}

.downloadBtnFailed:hover {
    color: white !important;
    background-color: red !important;
    border-color: red !important;
}
