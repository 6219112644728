.templateBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.templateBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.title {
    color: #487c84;
    font-weight: 700;
}

.small {
    font-size: 10px;
    margin-left: 3px;
}

