.center {
    align-content: center;
    justify-content: center;
    display: flex;
}

.marginHorizontal {
    margin-right: 1%;
    margin-left: 1%;
}

.header {
    background-color: #D8E2E5;
}
