

.btnStyle {
    background-color: #487c84;
    border-color: transparent !important;
    margin-left: 10px;
    margin-right: 10px;

}

.btnStyle:hover {
    background-color: #487c84 !important;
    border-color: transparent !important;
    margin-left: 10px;
    margin-right: 10px;
}
