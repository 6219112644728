.center {
    align-content: center;
    justify-content: center;
    display: flex;
}

.marginHorizontal {
    margin-right: 6%;
    margin-left: 6%;
}

.marginHorizontalChip {
    margin-right: 1%;
    margin-left: 1%;
}

.displayFlex {
    display: flex;
}
