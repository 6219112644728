.title {
    color: #487c84
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
    margin-left: 20px;
}

.text {
    font-size: small !important;
}

.uploadBtn > span {
    background-color: #487c84 !important;
    border-color: #487c84 !important;
}

.tool {
    display: flex;
}

.dropDown {
    width: 40%;
    margin-right: 10px;
}

.textArea {
    width: 60%;
}

.buttons {
    text-align: right;
    margin-top: 50px;
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;

}
