.title {
    color: #487c84
}

.text {
    font-size: small !important;
}

.underline-link {
    text-decoration: underline;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;

}

.fileName {
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: help;
}

.uploadBtn > span {
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    width: 55px;
}

.uploadSpinnerBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;

}

.uploadSpinnerBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;

}

.confirmBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
}

.confirmBtn:hover {
    color: #487c84 !important;
    background-color: white !important;
    border-color: #487c84 !important;
}

.rejectBtn {
    color: #487c84 !important;
    background-color: transparent;
    border-color: transparent;
}

.rejectBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: transparent !important;
}

.inputStyle {
    width: 100%;
}
