.cellWidth {
    width: 250px;
}

.bigCell {
    max-width: 350px;
}

.actionCell {
    width: 300px;
}

.newDC {
    background-color: #487c84;
    border-color: transparent !important;

}

.newDC:hover {
    background-color: #487c84 !important;
    border-color: transparent !important;

}

.buttonSendMC {
    background-color: #fbbd1e !important;
    border-color: #fbbd1e !important;
    height: 40px;
    width: auto;
    margin-top: 5px;
    margin-bottom: -3px;
}

.selectBtn {
    height: 10px;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
    margin-left: 20px;
}

.buttons {
    text-align: right;
    margin-top: 5px;
}


.overplayStyle {
    width: 35%
}

.description {
    white-space: normal;
}