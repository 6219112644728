
.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
    margin-left: 20px;
}

.actionBtn:hover {
    background-color: #487c84 !important;
}

.p-button-label {
    font-weight: 700 !important;
}

.rightButton {
    float: right;
}


.padding {
    padding-left: 10px;
    padding-right: 10px;
}

.padding10 {
    display: flex;
    flex-direction: row;
    padding-left: 10px
}
