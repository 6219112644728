.buttons {
    text-align: right;
    margin-top: 50px;
}

.actionBtn {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    margin-right: 0.5%;
    margin-left: 20px;
}

p {
    z-index: 10000;
}

.actionBtn:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    margin-right: 0.5%;

}

.progressTitle {
    position: relative;
    z-index: 11;
    text-align: center;
    margin-top: -6em;
    color: #487c84;
}
