.textArea {
    width: 100%;
}

.btnStyle {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;
    margin-left: 20px;
}

.btnStyle:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
}

.selectStyle {
    width: 49%;
}

