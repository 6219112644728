.buttons {
    text-align: right;
    margin-top: 5px;
}

.button {
    margin-left: 5px;
    margin-right: 5px;
}

.marginHorizontal {
    margin-right: 1%;
    margin-left: 1%;
}

.marginBottom {
    margin-bottom: 1%;
}
