.btnStyle {
    color: white !important;
    background-color: #487c84;
    border-color: #487c84;
    border-radius: 7px;
}

.btnStyle:hover {
    color: white !important;
    background-color: #487c84 !important;
    border-color: #487c84 !important;
    border-radius: 7px;
}

.position {
    margin-right: 10px;
}

.selectStyle {
    width: 49%;
}

.textArea {
    width: 100%;
}



